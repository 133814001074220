import { render, staticRenderFns } from "./orderInfo.vue?vue&type=template&id=46d74388&"
import script from "./orderInfo.vue?vue&type=script&lang=ts&"
export * from "./orderInfo.vue?vue&type=script&lang=ts&"
import style0 from "./orderInfo.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/root/workspace/com.jufair.vue_H3C2/components/SectionTitle/index.vue').default})
